import styled from 'styled-components'
import { breakpoint, size } from '../tokens'

export interface IContainerProps {
  maxWidth?: string
  bufferTop?: string | null
  bufferBottom?: string
  paddingTop?: string
  paddingBottom?: string
}

const Container = styled.div<IContainerProps>`
  max-width: ${({ maxWidth = breakpoint.large }) => maxWidth};
  margin-left: auto;
  margin-right: auto;
  padding-left: ${size.single};
  padding-right: ${size.single};
  ${({ bufferTop }) => bufferTop && `margin-top: ${bufferTop};`}
    ${({ bufferBottom }) => bufferBottom && `margin-bottom: ${bufferBottom};`}

    ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop};`}
    ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom};`}
`

export default Container
