import React, { FC } from 'react'
import Link from '../Atoms/Link'
import { colors } from '../tokens'

export interface INavItemProps {
  node: {
    slug: string
    title: string
  }
}

const NavItem: FC<INavItemProps> = ({ node }) => {
  const { slug, title } = node
  return (
    <li key={slug}>
      <Link colour={colors.base.primary} activeClassName="active" to={`/${slug}`}>
        {title}
      </Link>
    </li>
  )
}
export default NavItem
