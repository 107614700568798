import React, { FC } from 'react'
import styled from 'styled-components'
import ButtonStyles from './ButtonStyles'

import { colors } from '../tokens'

export interface IPrimaryCtaProps {
  tel: string
  cta: string
}

const Element = styled.a`
  ${ButtonStyles}
  margin: 1rem;
  color: ${colors.base.secondary};
`

const PrimaryCta: FC<IPrimaryCtaProps> = ({ tel, cta }) => <Element href={tel}>{cta}</Element>

export default PrimaryCta
