import React, { FC } from 'react'
import styled from 'styled-components'
import NavItem from '../../components/Molecules/NavItem'
import Container from '../Atoms/Container'
import Link from '../Atoms/Link'
import Wrapper from '../Atoms/Wrapper'
import { IPrimaryNavProps } from '../Templates/Entry'
import { borderradius, breakpoint, colors, size } from '../tokens'
import PrimaryCta from '../Atoms/PrimaryCta'

export interface IHeaderProps {
  siteTitle: string
  logo: string
  primaryNav: {
    edges: Array<{
      node: IPrimaryNavProps
    }>
  }
  primaryCta: string
  telephoneNumber: string
}

const MainNav = styled.ul`
  display: flex;
  flex-direction: row;
  list-style: none;
  overflow-x: auto;

  @media (max-width: ${breakpoint.small}) {
    padding: 0;
  }

  li {
    a {
      display: block;
      padding: ${size.single};
      text-decoration: none;
      border-radius: ${borderradius.medium};

      &:hover,
      &:active,
      &:focus {
        background-color: ${colors.neutral.medium};
        color: ${colors.base.secondary};
      }
      &.active {
        background-color: ${colors.neutral.medium};
        color: ${colors.base.secondary};
      }
    }
  }
`

const HeaderContainer = styled(Container)`
  overflow-x: hidden;

  @media (min-width: ${breakpoint.medium}) {
    display: flex;

    > a {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    > ul {
      flex: 3;
    }
  }
`

const Header: FC<IHeaderProps> = ({ primaryNav, siteTitle, logo, primaryCta, telephoneNumber }) => (
  <Wrapper backgroundColour={colors.neutral.dark} textColour={colors.base.primary} as="header">
    <HeaderContainer>
      <Link to={'/'}>
        <img src={logo} alt={siteTitle} width="100%" height="auto" />
      </Link>
      <MainNav>
        <li>
          <Link to="/" activeClassName="active">
            Home
          </Link>
        </li>
        {primaryNav ? primaryNav.edges.map((item) => NavItem(item)).reverse() : null}
      </MainNav>
      {primaryCta && <PrimaryCta tel={`tel:${telephoneNumber}`} cta={primaryCta} />}
    </HeaderContainer>
  </Wrapper>
)

export default Header
