import styled from 'styled-components'
import { breakpoint } from '../tokens'

export interface IRowProps {
  width: number
  breakWidth?: string
  swapColumns?: boolean
}

const Row = styled.div<IRowProps>`
  @media (min-width: ${({ breakWidth }) => (breakWidth ? breakWidth : breakpoint.medium)}) {
    display: grid;
    grid-template-columns: repeat(${({ width }) => (width ? width : 1)}, 1fr);
    grid-gap: 15px;

    ${({ swapColumns }) =>
      swapColumns &&
      `
      > *:nth-child(1) {
        order: 2;
      }
    `}
  }
`

export default Row
