import styled from 'styled-components'
import { breakpoint } from '../tokens'

export interface IWrapperProps {
  backgroundImage?: string
  backgroundColour?: string
  textColour?: string
  paddingTop?: string
  paddingBottom?: string
}

const Wrapper = styled.div<IWrapperProps>`
  width: 100%;
  background-color: ${({ backgroundColour }) => backgroundColour};
  ${({ backgroundImage }) =>
    backgroundImage && `background: url(${backgroundImage}) no-repeat center center fixed;`}
  background-position: fixed;
  color: ${({ textColour }) => textColour};
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};

  @media (min-width: ${breakpoint.medium}) {
    background-size: cover;
  }
`

export default Wrapper
