import styled from 'styled-components'
import { flexalign, size, breakpoint } from '../tokens'

export interface IColumnProps {
  verticalAlign?: string
  textAlign?: string
  bufferTop?: string
  bufferBottom?: string
  paddingTop?: string
  paddingBottom?: string
  bufferTopSmall?: string
  bufferBottomSmall?: string
  paddingTopSmall?: string
  paddingBottomSmall?: string
}

const Column = styled.div<IColumnProps>`
  display: flex;
  flex-direction: column;

  align-self: ${({ verticalAlign = flexalign.start }) => verticalAlign};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  > h1,
    > h2,
    > h3,
    > h4,
    > h5,
    > h6 {
    flex: 1;
  }
  > p {
    flex: 2;

    & ~ div[class*='Button'] {
      flex: 0;
    }
  }
  > img {
    width: 100%;
  }

  ${({ bufferTopSmall = size.single }) => bufferTopSmall && `margin-top: ${bufferTopSmall};`}
    ${({ bufferBottomSmall = size.single }) =>
      bufferBottomSmall && `margin-bottom: ${bufferBottomSmall};`}

    ${({ paddingTopSmall = size.single }) => paddingTopSmall && `padding-top: ${paddingTopSmall};`}
    ${({ paddingBottomSmall = size.single }) =>
      paddingBottomSmall && `padding-bottom: ${paddingBottomSmall};`}
    
  @media(min-width: ${breakpoint.small}) {
    ${({ bufferTop = size.single }) => bufferTop && `margin-top: ${bufferTop};`}
    ${({ bufferBottom = size.single }) => bufferBottom && `margin-bottom: ${bufferBottom};`}

    ${({ paddingTop = size.single }) => paddingTop && `padding-top: ${paddingTop};`}
    ${({ paddingBottom = size.single }) => paddingBottom && `padding-bottom: ${paddingBottom};`}
  }
`

export default Column
