import React, { FC } from 'react'
import styled from 'styled-components'
import Link from '../Atoms/Link'
import { withPrefix } from 'gatsby'

import NavItem from '../../components/Molecules/NavItem'
import Column from '../Atoms/Column'
import Container from '../Atoms/Container'
import Row from '../Atoms/Row'
import Wrapper from '../Atoms/Wrapper'
import { IPrimaryNavProps, ISecondaryNavProps } from '../Templates/Entry'
import { borderradius, colors, size } from '../tokens'
import PrimaryCta from '../Atoms/PrimaryCta'

export interface IFooterProps {
  siteTitle: string
  logo: string
  primaryNav: {
    edges: Array<{ node: IPrimaryNavProps }>
  }
  secondaryNav: {
    edges: Array<{ node: ISecondaryNavProps }>
  }
  primaryCta: string
  telephoneNumber: string
}

const SecondaryNav = styled.ul`
  list-style: none;
  padding-left: 0;

  li {
    a {
      display: block;
      padding: ${size.single};
      text-decoration: none;
      border-radius: ${borderradius.medium};
      color: ${colors.base.primary};

      &:hover,
      &:active,
      &:focus {
        background-color: ${colors.neutral.medium};
        color: ${colors.base.secondary};
      }
      &.active {
        background-color: ${colors.neutral.medium};
        color: ${colors.base.secondary};
      }
    }
  }
`

const Footer: FC<IFooterProps> = ({
  secondaryNav,
  primaryNav,
  siteTitle,
  logo,
  telephoneNumber,
  primaryCta,
}) => (
  <Wrapper backgroundColour={colors.base.secondary} as="footer" textColour={colors.base.primary}>
    <Container>
      {console.log(logo)}
      <Row width={4}>
        <Column>
          <Link to={'/'}>
            <img src={logo} alt={siteTitle} width="100%" height="auto" />
          </Link>
          <h2>About This Site</h2>
          <Link to={withPrefix('/')}>{siteTitle}</Link>
          <p>Copyright Head-turner Windows. Some rights reserved. Made by Benjamin Read.</p>
        </Column>
        <Column />
        <Column>
          {primaryNav ? (
            <>
              <h3>This Site</h3>
              <SecondaryNav>{primaryNav.edges.map((item) => NavItem(item)).reverse()}</SecondaryNav>
            </>
          ) : null}
        </Column>
        <Column>
          <h3>Navigate</h3>
          <SecondaryNav>
            {secondaryNav ? secondaryNav.edges.map((item) => NavItem(item)).reverse() : null}
          </SecondaryNav>
          {primaryCta && <PrimaryCta tel={`tel:${telephoneNumber}`} cta={primaryCta} />}
        </Column>
      </Row>
    </Container>
  </Wrapper>
)

export default Footer
